import {
  Box,
  Typography,
  Button,
  Grid,
  Paper,
  InputLabel,
  MenuItem,
  Stack,
} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import React, { useState, useEffect, useCallback } from "react";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import { useNavigate, useLocation } from "react-router-dom";
import { Virtuoso } from "react-virtuoso";

//Colors
import {
  textWhite,
  TextBlue,
  darkBlue,
  green,
  red,
} from "../../styled-Component/colors";
//STYLES
import {
  hCenter,
  vCenter,
  colorWhite,
  WhiteBorder,
  bgDarkBlue,
  pointer,
} from "../../styled-Component/elements";
//Icons
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
//images
import PopulModel from "./helper/popupMdel";
import { No_Data } from "../../helper/Nodata";
//Reduc
import { useSelector, useDispatch } from "react-redux";
import { AddInvoice, AddItem } from "../../store/counterSlice";
import "./style.css";
import axios from "../../Axios/Axios";
import { Url } from "../../Axios/Axios";
import { Loader } from "../../helper/loader";
import { Toast } from "../../helper/toaster";
import { Skeleton } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { apicall } from "../../helper/apifunction";

function InvoiceList() {
  const location = useLocation();
  const invoceList = useSelector((state) => state.counter.invoice);
  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const today = new Date();
  const formattedDate = today.toString("MMMM dd, yyyy");
  const [value, setValue] = React.useState(formatDate(formattedDate));

  function formatDate(date) {
    return `${dayjs(date).format("YYYY-MM-DD")}`;
  }

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const dispatch = useDispatch();

  const [invoices, setinvoices] = useState([]);
  const [filters, setFilter] = React.useState("All");

  const handleChange = (event) => {
    setFilter(event.target.value);
    setOpenFilter(false);
  };

  //   const { isLoading, error, data, refetch } = useQuery({
  //     queryKey: ["repoData", value],
  //     queryFn: apicall(
  //       "invoices",
  //       "post",
  //       location?.state?.where === "search"
  //         ? JSON.stringify({
  //             po_no: location?.state?.data,
  //             invoice_date: "",
  //           })
  //         : JSON.stringify({ invoice_date: value, po_no: "" })
  //     ),

  //     staleTime: 60000,
  //   });
  //   console.log(data);

  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const datas_from_db = async () => {
    setIsLoading(true);
    try {
      const response = await apicall(
        "invoices",
        "post",
        location?.state?.where == "search"
          ? JSON.stringify({ po_no: location?.state?.data, invoice_date: "" })
          : JSON.stringify({ invoice_date: "", po_no: "" })
      );

      const data = await response.json();
      setData(data);
    } catch (error) {
      Toast("error", "Data Not Found", 1800);
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    datas_from_db();
  }, [value]);

  
  useEffect(() => {
    if (data) {
      if (data?.data?.length > 0) {
        dispatch(AddInvoice(data.data));
        setinvoices(data.data);

        if (location?.state?.where == "search" && location?.state?.data != "") {
          let filteredData = data.data.filter(
            (data) => data.invoice_no == location.state.data
          );

          if (filteredData.length > 0) {
            setinvoices(filteredData);
          } else {
            setinvoices([]);
          }
        }
      }
    } else {
      setinvoices([]);
    }
  }, [data, location?.state]);

  const filterDate = () => {
    return invoices.filter((data) =>
      filters == "All" ? data : filters == data?.status ? data : null
    );
  };

  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [selected, setselected] = useState("");
  const [openFilter, setOpenFilter] = useState(false);

  const handleOpen = () => {
    setOpenFilter(!openFilter);
  };

  const toggleDrawer = (item) => {
    setOpen(!open);
    setselected(item);
  };
  return (
    <>
      {/*Model*/}

      {/*Model End*/}
      <Box sx={{ px: 2 }}>
        <Box>
          <Grid container sx={{ vCenter, pt: 2 }}>
            <Grid item xs={4}>
              <ArrowBackIosNewIcon
                onClick={() => {
                  navigate("/dashboard");
                }}
                sx={{
                  fontSize: "20px",
                  padding: 0.5,
                  border: `1px solid ${TextBlue}`,
                  color: TextBlue,
                  borderRadius: "6px",
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <Typography sx={{ color: "white" }}>Invoice List</Typography>
            </Grid>
          </Grid>
        </Box>

        {/*Filter*/}

        <Box
          className="Inputfiels"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              width: "55%",
              display: "flex",
              flexDirection: {
                xs: "column",
                sm: "row",
              },
              alignItems: {
                xs: "start",
                sm: "center",
              },
              mt: 3,
              gap: 2,
              "& .MuiInputBase-input": {
                color: "white !important",
                fontWeight: "bold",
                padding: "4px 4px 0px 4px",
                fontSize: "14px",
              },
              "& .MuiFormControl-root": {
                border: ".5px solid white !important",
              },
              "& button": {
                padding: "4px !important",
                fontSize: "12px !important",
                color: "white !important",
              },
            }}
          >
            <Typography sx={{ color: "white" }} component="p" variant="h6">
              PO Date:{" "}
            </Typography>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              {/* <DatePicker
                                value={value}
                                onChange={(newValue) => setValue(newValue)}
                            /> */}
              <DatePicker
                value={dayjs(value)}
                onChange={(newValue) => {
                  const formattedDate = dayjs(newValue).format("YYYY-MM-DD");
                  setValue(formattedDate);
                  datas_from_db();
                }}
              />
            </LocalizationProvider>
          </Box>

          <FormControl sx={{ width: "30%", mx: "end" }}>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              open={openFilter}
              value={filters}
              sx={{
                visibility: openFilter ? "visible" : "hidden",
                color: "white !important",
                border: "1px solid white",
                p: 0,
                fontSize: "12px",
              }}
              onChange={handleChange}
            >
              <MenuItem value={"All"} selected={true}>
                All
              </MenuItem>
              <MenuItem value={"0"}>pending</MenuItem>
              <MenuItem value={"1"}>In-Progress</MenuItem>
              <MenuItem value={"2"}>completed</MenuItem>
            </Select>
          </FormControl>
          <FilterAltIcon
            sx={{ color: "white", fontSize: "22px" }}
            onClick={handleOpen}
          />
        </Box>

        {/*------------------------------------------------InVoice Lists-----------------------------------------------*/}
        <Box sx={{ mt: 2 }}>
          {!isLoading && filterDate().length > 0 && (
            <Virtuoso
              style={{ height: "90vh" }}
              totalCount={filterDate().length}
              data={filterDate()}
              itemContent={(_, item) => (
                <Box
                  onClick={() => toggleDrawer(item)}
                  sx={{
                    ...bgDarkBlue,
                    ...pointer,
                    px: 1.5,
                    py: 1,
                    borderRadius: 3,
                    mb: 1.3,
                    boxShadow: "0px 1px 1px 0px grey",
                  }}
                >
                  {/*-----------------------------------------------------*/}
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      backgroundColor: "",
                    }}
                  >
                    <Box>
                      <Typography
                        sx={{
                          ...colorWhite,
                          fontWeight: "600",
                          lineHeight: "1.3rem",
                          fontSize: "16px",
                        }}
                      >
                        {`${item?.wCode ? item?.wCode : "wh002"} (${
                          item.invoice_no
                        })`}
                      </Typography>
                      <Typography
                        sx={{
                          ...colorWhite,
                          fontWeight: "500",
                          lineHeight: "1.1rem",
                          fontSize: "11px",
                        }}
                      >
                        {/* {`${new Date(
                                            item.invoice_date
                                        ).toLocaleTimeString()} ${new Date(
                                            item.invoice_date
                                        ).toLocaleDateString()}`} */}
                        {`${new Date(item.invoice_date).toLocaleDateString()}`}
                      </Typography>
                    </Box>

                    <Typography
                      sx={{
                        fontSize: "13px",
                        color:
                          item?.status == 0
                            ? "red"
                            : item?.status == 1
                            ? "lightblue"
                            : "green",
                      }}
                    >
                      {item?.status === 1
                        ? "Completed"
                        : item?.status === 2
                        ? "In-Progress"
                        : item?.status === 0
                        ? "Pending"
                        : ""}
                    </Typography>
                  </Box>

                  {/*-----------------------------------------------------*/}
                </Box>
              )}
            />
          )}
          {/* NO Data */}
          {!isLoading && filterDate().length <= 0 && <No_Data />}

          {/* skeleton While Loading */}
          {isLoading && (
            <Stack spacing={-1} sx={{ width: "100%" }}>
              <Skeleton
                sx={{
                  backgroundColor: "grey",
                  border: "0px solid white",
                  height: "69px",
                  mb: 0,
                }}
              />
              <Skeleton
                sx={{
                  backgroundColor: "grey",
                  border: "0px solid white",
                  height: "69px",
                  mb: 0,
                }}
              />
              <Skeleton
                sx={{
                  backgroundColor: "grey",
                  border: "0px solid white",
                  height: "69px",
                  mb: 0,
                }}
              />
              <Skeleton
                sx={{
                  backgroundColor: "grey",
                  border: "0px solid white",
                  height: "69px",
                  mb: 0,
                }}
              />
              <Skeleton
                sx={{
                  backgroundColor: "grey",
                  border: "0px solid white",
                  height: "60px",
                }}
              />
              <Skeleton
                sx={{
                  backgroundColor: "grey",
                  border: "0px solid white",
                  height: "60px",
                }}
              />
              <Skeleton
                sx={{
                  backgroundColor: "grey",
                  border: "0px solid white",
                  height: "60px",
                }}
              />
              <Skeleton
                sx={{
                  backgroundColor: "grey",
                  border: "0px solid white",
                  height: "60px",
                }}
              />
              <Skeleton
                sx={{
                  backgroundColor: "grey",
                  border: "0px solid white",
                  height: "55px",
                }}
              />
              <Skeleton
                sx={{
                  backgroundColor: "grey",
                  border: "0px solid white",
                  height: "55px",
                }}
              />
              <Skeleton
                sx={{
                  backgroundColor: "grey",
                  border: "0px solid white",
                  height: "55px",
                }}
              />
              <Skeleton
                sx={{
                  backgroundColor: "grey",
                  border: "0px solid white",
                  height: "55px",
                }}
              />
              <Skeleton
                sx={{
                  backgroundColor: "grey",
                  border: "0px solid white",
                  height: "50px",
                }}
              />
              <Skeleton
                sx={{
                  backgroundColor: "grey",
                  border: "0px solid white",
                  height: "50px",
                }}
              />
              <Skeleton
                sx={{
                  backgroundColor: "grey",
                  border: "0px solid white",
                  height: "50px",
                }}
              />
              <Skeleton
                sx={{
                  backgroundColor: "grey",
                  border: "0px solid white",
                  height: "50px",
                }}
              />
            </Stack>
          )}
        </Box>
      </Box>

      <PopulModel
        open={open}
        setOpen={setOpen}
        selected={selected}
        where="invoice"
        po_no={location?.state?.data}
      />
    </>
  );
}

export default InvoiceList;
