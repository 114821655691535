import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import React from "react";
// Styles
import "../QrStyles.css";

// Qr Scanner
import QrScanner from "qr-scanner";
import QrFrame from "../../../Assets/qr-frame.svg";
import { Url } from "../../../Axios/Axios";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import PopupForRackPlacement from "../../../components/TablePage/PopupForRackPlacement";

const QrReader = ({
  datas,
  setScanStatus,
  loadedData,
  where,
  setPrData,
  binLocation,
  setBinLocation,
  isScanned,
  setIsScanned,
}) => {
  // QR States

  const navigate = useNavigate();
  const scanner = useRef();
  const videoEl = useRef(null);
  const qrBoxEl = useRef(null);
  const [qrOn, setQrOn] = useState(true);

  const [isProcessing, setIsProcessing] = useState(false);

  // Result
  const [scannedResult, setScannedResult] = useState("");
  const [scannedData, setScannedData] = useState([]);

  const fetchData = async (resultData) => {
    const token = localStorage.getItem("userToken");
    if (!token) {
      throw new Error("User token is not available");
    }
    try {
      const response = await fetch(`${Url}check-binloc`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          bin_loc: resultData,
        }),
      }).then((res) => res.json());

      setScannedData(response.data);

      if (response.data.item_code === datas.item_code) {
        setScannedResult("Qr validated");
        scanner?.current?.stop();
        setScanStatus(true);
        setIsProcessing(false);
      } else {
        // setScannedResult("InValid Qr Code");
        handleClickOpen();
        setIsProcessing(false);
      }
    } catch (error) {
      console.log(error);
      setIsProcessing(false);
    }
  };

  // Success
  const onScanSuccess = async (result) => {
    if (result && !isScanned) {
      setIsProcessing(true); // Mark as processing
      setIsScanned(true); // Set the flag to true after the first scan

      if (where === "invoiceList") {
        setBinLocation(result.data);
        fetchData(result.data);
      }
    }
  };

  // Fail
  const onScanFail = (err) => {
    // console.log(err);
  };

  useEffect(() => {
    if (videoEl?.current && !scanner.current) {
      // 👉 Instantiate the QR Scanner
      scanner.current = new QrScanner(videoEl?.current, onScanSuccess, {
        onDecodeError: onScanFail,
        // 📷 This is the camera facing mode. In mobile devices, "environment" means back camera and "user" means front camera.
        preferredCamera: "environment",
        // 🖼 This will help us position our "QrFrame.svg" so that user can only scan when qr code is put in between our QrFrame.svg.
        highlightScanRegion: true,
        // 🔥 This will produce a yellow (default color) outline around the qr code that we scan, showing a proof that our qr-scanner is scanning that qr code.
        highlightCodeOutline: true,
        // 📦 A custom div which will pair with "highlightScanRegion" option above 👆. This gives us full control over our scan region.
        overlay: qrBoxEl?.current || undefined,
      });

      // 🚀 Start QR Scanner
      scanner?.current
        ?.start()
        .then(() => setQrOn(true))
        .catch((err) => {
          if (err) setQrOn(false);
        });
    }

    // 🧹 Clean up on unmount.
    // 🚨 This removes the QR Scanner from rendering and using camera when it is closed or removed from the UI.
    return () => {
      if (!videoEl?.current) {
        scanner?.current?.stop();
      }
    };
  }, []);

  // ❌ If "camera" is not allowed in browser permissions, show an alert.
  useEffect(() => {
    if (!qrOn)
      alert(
        "Camera is blocked or not accessible. Please allow camera in your browser permissions and Reload."
      );
  }, [qrOn]);

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    scanner?.current?.stop();
    setIsProcessing(false);
    navigate(-1);
  };

  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const handleCloseDrawer = () => {
    setDrawerOpen(false);
    navigate(-1);
  };

  const handleOpenDrawer = () => {
    setOpen(false);
    scanner?.current?.stop();
    setIsProcessing(false);
    setDrawerOpen(true);
  };

  return (
    <div className="qr-reader">
      {/* QR */}
      <video ref={videoEl}></video>
      <div ref={qrBoxEl} className="qr-box">
        <img
          src={QrFrame}
          alt="Qr Frame"
          width={256}
          height={256}
          className="qr-frame"
        />
      </div>

      {/* Show Data Result if scan is success */}
      {scannedResult && (
        <p
          style={{
            position: "absolute",
            top: 0,
            left: 28,
            zIndex: 1,
            backgroundColor: "black",
            textAlign: "center",
            color: "white",
          }}
        >
          {scannedResult}
        </p>
      )}

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Bin Location Not mapped for this item, Do you want dynamic location
            ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>No</Button>
          <Button onClick={handleOpenDrawer} autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>

      <PopupForRackPlacement
        handleCloseDrawer={handleCloseDrawer}
        open={drawerOpen}
        binLocation={binLocation}
        disabled={true}
        productDetail={false}
        Qty={false}
      />
    </div>
  );
};

export default QrReader;
