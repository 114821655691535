import {
  Avatar,
  Box,
  Button,
  FormControl,
  IconButton,
  InputLabel,
  ListItemIcon,
  Menu,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React from "react";
import HeaderDrawer from "../../helper/HeaderDrawer";
import { colorWhite, hCenter, vCenter } from "../../styled-Component/elements";
import { Logout, NotificationsNone } from "@mui/icons-material";
import { Toast } from "../../helper/toaster";
import { useNavigate } from "react-router-dom";
import { textWhite } from "../../styled-Component/colors";
import Buttons from "react-bootstrap/Button";
import QRCode from "react-qr-code";
import { Url } from "../../Axios/Axios";

const QRGenerator = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [qrcodeValue, setQrcodeValue] = React.useState([]);
  const [selectedQRCode, setSelectedQRCode] = React.useState("");
  const [generatedBarCode, setGeneratedBarCode] = React.useState(false);
  const menuOpen = Boolean(anchorEl);
  const userJSON = localStorage.getItem("user");
  const user = userJSON && JSON.parse(userJSON);
  const navigate = useNavigate();

  const getAllBin = async () => {
    try {
      const token = localStorage.getItem("userToken");
      if (!token) {
        throw new Error("User token is not available");
      }

      const response = await fetch(`${Url}getallBin`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      const done = await response.json();

      if (response.ok) {
        setQrcodeValue(done.data.binloc);
      }
    } catch (error) {
      console.log(error);
    }
  };

  React.useEffect(() => {
    getAllBin();
  }, []);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const generateQRCode = () => {
    setGeneratedBarCode(true);
  };

  const printQRCode = () => {
    const qrcode = document.getElementById("qrcode");
    const qrcodeValue = document.getElementById("qrcode-value").innerText;
    const printWindow = window.open("", "Print QR Code");
    printWindow.document.write(`
      <html>
        <head>
          <style>
            body { display: flex; justify-content: center; align-items: center; flex-direction: column;  margin: 0; height: 100%; }
          </style>
        </head>
        <body>
          ${qrcode.outerHTML}
          <p style="text-align: center; font-size: 28px; font-family: Arial, sans-serif;">${qrcodeValue}</p>
        </body>
      </html>
    `);
    printWindow.document.close();
    printWindow.print();
  };

  const handleLogout = async () => {
    setAnchorEl(null);
    localStorage.removeItem("user");
    localStorage.removeItem("userToken");
    navigate("/sign-in");
    Toast("success", "Logout Successful", 1600);
  };

  const handleSelectChange = (event) => {
    setSelectedQRCode(event.target.value);
  };

  return (
    <Box>
      <Box sx={{ width: "93%", mx: "auto", mt: 2 }}>
        <Box
          sx={{
            ...hCenter,
            ...vCenter,
            boxSizing: "border-box",
            px: 2,
            backgroundColor: "#001B3B",
            py: "1.4rem",
            borderRadius: 3,
          }}
        >
          <HeaderDrawer />
          <Box>
            <Typography
              variant="h6"
              display="block"
              sx={{
                ...colorWhite,
                fontWeight: 600,
                my: 0,
                py: 0,
                lineHeight: "1rem ",
              }}
            >
              Racks QR Generation
            </Typography>
            <Typography
              variant="span"
              display="block"
              sx={{
                ...colorWhite,
                my: 0,
                py: 0,
                fontSize: "12px",
              }}
            ></Typography>
          </Box>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "0.5rem",
            }}
          >
            <NotificationsNone sx={{ color: textWhite }} />
            <>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  textAlign: "center",
                }}
              >
                <Tooltip title="Account settings">
                  <IconButton
                    onClick={handleClick}
                    size="small"
                    aria-controls={menuOpen ? "account-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={menuOpen ? "true" : undefined}
                  >
                    <Avatar sx={{ width: 22, height: 22 }}>
                      {user["name"][0].toUpperCase()}
                    </Avatar>
                  </IconButton>
                </Tooltip>
              </Box>
              <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={menuOpen}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: "visible",
                    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                    mt: 1.5,
                    "& .MuiAvatar-root": {
                      width: 22,
                      height: 22,
                      ml: -0.5,
                      mr: 1,
                    },
                    "&::before": {
                      content: '""',
                      display: "block",
                      position: "absolute",
                      top: 0,
                      right: 14,
                      width: 10,
                      height: 10,
                      bgcolor: "background.paper",
                      transform: "translateY(-50%) rotate(45deg)",
                      zIndex: 0,
                    },
                  },
                }}
                transformOrigin={{
                  horizontal: "right",
                  vertical: "top",
                }}
                anchorOrigin={{
                  horizontal: "right",
                  vertical: "bottom",
                }}
              >
                <MenuItem onClick={handleLogout}>
                  <ListItemIcon>
                    <Logout fontSize="small" />
                  </ListItemIcon>
                  Logout
                </MenuItem>
              </Menu>
            </>
          </Box>
        </Box>
      </Box>
      <Box sx={{ width: "93%", mx: "auto", pt: 4, pb: 4 }}>
        {/* <iframe
          src="https://wareve.adetive.com/qr"
          frameBorder="0"
          width="100%"
          style={{ border: "none", height: "calc(100vh - 76px + 16px + 32px)", borderRadius: "10px" }}
        ></iframe> */}

        <Box
          sx={{
            textAlign: "center",
            width: { xs: "80%", md: "35%" },
            mx: "auto",
          }}
        >
          <Box sx={{ textAlign: "start" }}>
            <Typography
              variant="span"
              display="block"
              sx={{
                color: "white",
                my: 1,
                py: 0,
                fontSize: "1rem",
              }}
            >
              Choose Racks
            </Typography>

            <FormControl
              fullWidth
              sx={{ background: "white", borderRadius: 1 }}
            >
              <Select
                // placeholder="Choose Racks"
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={selectedQRCode}
                onChange={handleSelectChange}
                size="small"
              >
                {qrcodeValue?.map((item, index) => (
                  <MenuItem value={item} key={index}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>

          <Box sx={{ display: "flex", justifyContent: "space-between", mt: 3 }}>
            <Buttons
              variant="primary"
              type="submit"
              // disabled={
              //   loadData.Po_no != "" &&
              //   loadData.Po_no != " " &&
              //   loadData.wh_cd != "" &&
              //   loadData.wh_cd != ""
              //     ? false
              //     : true
              // }
              onClick={generateQRCode}
            >
              Submit
            </Buttons>
            {/* <Buttons
              variant="danger"
              type="submit"
              // onClick={handleClearLoad}
              sx={{ mr: 1 }}
            >
              Clear
            </Buttons> */}
          </Box>
        </Box>
        {generatedBarCode && (
          <Box
            sx={{
              mt: 6,
              mx: "auto",
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <Box id="qrcode" sx={{ background: "white", p: 2 }}>
              <QRCode value={selectedQRCode} />
            </Box>

            <Typography
              variant="h5"
              sx={{ mt: 2, mb: 0 }}
              align="center"
              color="white"
              id="qrcode-value"
            >
              {selectedQRCode}
            </Typography>

            <Box sx={{ mt: 2 }}>
              <Button variant="contained" onClick={printQRCode}>
                Print
              </Button>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default QRGenerator;
