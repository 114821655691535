import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { Box } from "@mui/material";
import { BgBlackImage } from "./styled-Component/colors";

import Login from "./layout/Auth/login";
import CollectorLanding from "./layout/CollectorLanding/CollectorLandingPage";
import PlacerLanding from "./layout/PlacerLanding/placerLandingPage";
import MainPage from "./layout/MainPage/Mainpage";
import IconList from "./layout/InVoiceList/InVoiceList";
import Dashboard from "./layout/dashboard/dashboard";
import Picking from "./layout/Btvpicking/picking";
import TablePage from "./layout/TablePage/TablePage";
import GraphPage from "./layout/GraphPage/GraphPage";
import QrCodePage from "./layout/QrCode/Qrcode";
import Receiving from "./layout/purchase/receiving";
import QRBarScanner from "./layout/QRBarScanner/QRBarScanner";

import ProdcutFinder from "./layout/productFinder/ProductFinder";
import AdminPage from "./layout/Admin/index";
import UsersPage from "./layout/Users/index";
import Register from "./layout/Auth/register";

import ProtectedRoutes from "./ProtectedRoutes";
import Headers from "./layout/headers/index";
import QRGenerator from "./layout/QRGenerator/QRGenerator";
import POGRN from "./layout/report/po-grn/POGRNReport";

function App() {
  // document.title = "WMS Management";

  return (
    <Box sx={{ backgroundColor: BgBlackImage }}>
      <Routes>
        <Route path="/sign-in" element={<Login />} />

        <Route element={<ProtectedRoutes />}>
          <Route
            path="/mainPage"
            element={
              <>
                <Headers />
                <MainPage />
              </>
            }
          />
          <Route path="/collector" element={<CollectorLanding />} />
          <Route path="/placer" element={<PlacerLanding />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/picking" element={<Picking />} />
          <Route path="/receiving" element={<Receiving />} />
          <Route path="/InvoiceList" element={<IconList />} />
          <Route path="/TablePage" element={<TablePage />} />
          <Route path="/GraphPage" element={<GraphPage />} />
          <Route path="/QrCode" element={<QrCodePage />} />
          <Route path="/qr" element={<QRGenerator />} />
          <Route path="/product-finder" element={<ProdcutFinder />} />
          <Route path="/scanner" element={<QRBarScanner />} />
          <Route path="/superAdminLogin" element={<MainPage />} />
          <Route path="/credentials" element={<UsersPage />} />
          <Route path="/report/po-grn" element={<POGRN />} />
          <Route
            path="/Register"
            element={
              <>
                <Headers />
                <Register />
              </>
            }
          />
        </Route>

        <Route path="*" element={<Navigate to="/sign-in" replace />} />
      </Routes>
    </Box>
  );
}

export default App;
