import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Url } from "../../Axios/Axios";
import { colorWhite } from "../../styled-Component/elements";
import { green, red } from "@mui/material/colors";
import { Global } from "@emotion/react";
import { useLocation } from "react-router-dom";

export default function TemporaryDrawer({
  handleCloseDrawer,
  open,
  binLocation,
  disabled,
  inBuitScanner,
  popupData,
  onSubmit,
  productDetail = true,
  Qty = true,
}) {
  const location = useLocation();
  const [qrcodeValue, setQrcodeValue] = React.useState([]);
  const [selectedQRCode, setSelectedQRCode] = React.useState("");
  const [scannedBinData, setScannedBinData] = React.useState({
    itm_cd: popupData?.item_code,
    wh_cd: location?.state?.data?.wCode,
    po_no: location.state.data.invoice_no,
    binLocation: binLocation,
    qty: "",
  });

  React.useEffect(() => {
    setScannedBinData({
      ...scannedBinData,
      itm_cd: popupData?.item_code,
    });
  }, [popupData]);
  const [errorScannedBinLocation, setErrorScannedBinLocation] =
    React.useState(false);
  const drawerBleeding = 10;

  const handleSelectChange = (event) => {
    setSelectedQRCode(event.target.value);
  };

  const getAllBin = async () => {
    try {
      const token = localStorage.getItem("userToken");
      if (!token) {
        throw new Error("User token is not available");
      }

      const response = await fetch(`${Url}getallBin`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      const done = await response.json();

      if (response.ok) {
        setQrcodeValue(done.data.binloc);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleChange = (e) => {
    if (e.target.name === "binLocation") {
      setScannedBinData({
        ...scannedBinData,
        binLocation: e.target.value,
      });
      if (qrcodeValue.includes(e.target.value)) {
        setSelectedQRCode(e.target.value);
        setErrorScannedBinLocation(false);
      } else {
        setSelectedQRCode("");
        setErrorScannedBinLocation(true);
      }
    } else if (e.target.name === "qty") {
      setScannedBinData({
        ...scannedBinData,
        qty: e.target.value,
      });
    }
  };

  React.useEffect(() => {
    getAllBin();
  }, []);

  const handelSubmit = async () => {
    await onSubmit(scannedBinData);
  };

  const DrawerList = (
    <Box sx={{ pt: 2 }} role="presentation">
      <Box
        sx={{
          p: 2,
          maxWidth: 400,
          width: "100%",
          mx: "auto",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          gap: 3,
        }}
      >
        {productDetail && (
          <>
            <Typography variant="h6" component="h6" textAlign="center">
              Product Details
            </Typography>
            <Stack spacing={1}>
              <Typography variant="body1" component="p" sx={{}}>
                Item Code:{" " + popupData?.item_code}
              </Typography>
              <Typography variant="body1" component="p">
                Item Name:{" " + popupData?.item_name}
              </Typography>
              <Typography variant="body1" component="p">
                Qty:{" " + popupData?.totQty}
              </Typography>
            </Stack>
          </>
        )}
        {inBuitScanner && (
          <Box>
            <TextField
              id="outlined-basic"
              size="small"
              label="Scan Bin"
              fullWidth
              placeholder="Bin Location"
              name="binLocation"
              variant="outlined"
              value={scannedBinData.binLocation}
              error={errorScannedBinLocation}
              helperText={
                errorScannedBinLocation ? "No Available Bin Location" : ""
              }
              autoFocus
              onChange={(e) => handleChange(e)}
            />
          </Box>
        )}

        <Box>
          <FormControl sx={{ width: "100%", borderRadius: 1 }} size="small">
            <InputLabel id="demo-select-small-label">Available Bin</InputLabel>
            <Select
              labelId="demo-select-small-label"
              id="demo-select-small"
              value={selectedQRCode}
              label="Available Bin"
              onChange={handleChange}
              disabled={disabled}
            >
              {qrcodeValue?.map((item, index) => (
                <MenuItem value={item} key={index}>
                  {item}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>

        {Qty && (
          <Box>
            <TextField
              id="outlined-basic"
              size="small"
              label="Qty"
              fullWidth
              placeholder="Qty"
              type="number"
              variant="outlined"
              value={scannedBinData.Qty}
              name="qty"
              onChange={(e) => handleChange(e)}
            />
          </Box>
        )}
        <Stack direction="row" justifyContent="space-between" mt={2}>
          <Button
            variant="contained"
            onClick={handelSubmit}
            sx={{ background: green[600] }}
          >
            Submit
          </Button>
          <Button
            variant="contained"
            sx={{ background: red[500] }}
            onClick={handleCloseDrawer}
          >
            Cancel
          </Button>
        </Stack>
      </Box>
    </Box>
  );

  return (
    <div>
      {
        <Global
          styles={{
            ".MuiDrawer-root > .MuiPaper-root": {
              height: `calc(82% - ${drawerBleeding}px)`,
              overflow: "visible",
              borderTopLeftRadius: "40px",
              borderTopRightRadius: "40px",
              borderTop: "2px solid white",
              backgroundColor: "white",
            },
          }}
        />
      }
      <Drawer open={open} anchor="bottom" onClose={handleCloseDrawer}>
        {DrawerList}
      </Drawer>
    </div>
  );
}
