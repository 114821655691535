import {
  Avatar,
  Box,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from "@mui/material";
import React from "react";
import { colorWhite, hCenter, vCenter } from "../styled-Component/elements";
import HeaderDrawer from "./HeaderDrawer";
import { Logout, NotificationsNone } from "@mui/icons-material";
import { textWhite } from "../styled-Component/colors";
import { Toast } from "./toaster";
import { useNavigate } from "react-router-dom";

const Header = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const menuOpen = Boolean(anchorEl);
  const userJSON = localStorage.getItem("user");
  const user = userJSON && JSON.parse(userJSON);
  const navigate = useNavigate();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = async () => {
    setAnchorEl(null);
    localStorage.removeItem("user");
    localStorage.removeItem("userToken");
    navigate("/sign-in");
    Toast("success", "Logout Successful", 1600);
  };
  return (
    <Box
      sx={{
        ...hCenter,
        ...vCenter,
        boxSizing: "border-box",
        px: 2,
        backgroundColor: "#001B3B",
        py: "1.4rem",
        borderRadius: 3,
      }}
    >
      <HeaderDrawer />
      <Box>
        <Typography
          variant="h6"
          display="block"
          sx={{
            ...colorWhite,
            fontWeight: 600,
            my: 0,
            py: 0,
            lineHeight: "1rem ",
          }}
        >
          Purchase Receiving
        </Typography>
        <Typography
          variant="span"
          display="block"
          sx={{
            ...colorWhite,
            my: 0,
            py: 0,
            fontSize: "12px",
          }}
        ></Typography>
      </Box>

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "0.5rem",
        }}
      >
        <NotificationsNone sx={{ color: textWhite }} />
        <>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <Tooltip title="Account settings">
              <IconButton
                onClick={handleClick}
                size="small"
                aria-controls={menuOpen ? "account-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={menuOpen ? "true" : undefined}
              >
                <Avatar sx={{ width: 22, height: 22 }}>
                  {user["name"][0].toUpperCase()}
                </Avatar>
              </IconButton>
            </Tooltip>
          </Box>
          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={menuOpen}
            onClose={handleClose}
            onClick={handleClose}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: "visible",
                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                mt: 1.5,
                "& .MuiAvatar-root": {
                  width: 22,
                  height: 22,
                  ml: -0.5,
                  mr: 1,
                },
                "&::before": {
                  content: '""',
                  display: "block",
                  position: "absolute",
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: "background.paper",
                  transform: "translateY(-50%) rotate(45deg)",
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{
              horizontal: "right",
              vertical: "top",
            }}
            anchorOrigin={{
              horizontal: "right",
              vertical: "bottom",
            }}
          >
            <MenuItem onClick={handleLogout}>
              <ListItemIcon>
                <Logout fontSize="small" />
              </ListItemIcon>
              Logout
            </MenuItem>
          </Menu>
        </>
      </Box>
    </Box>
  );
};

export default Header;
