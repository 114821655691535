import React, { useState } from "react";
import PropTypes from "prop-types";
import { Global } from "@emotion/react";
import { styled } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { grey } from "@mui/material/colors";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import Typography from "@mui/material/Typography";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import TableViewIcon from "@mui/icons-material/TableView";
import BarChartIcon from "@mui/icons-material/BarChart";
import { useNavigate } from "react-router-dom";
//Color
import {
  TextBlue,
  darkBlue,
  overDarkBlue,
} from "../../../styled-Component/colors";
import { vCenter, colorWhite, Btn } from "../../../styled-Component/elements";
import TextField from "@mui/material/TextField";
import { isNumberOnly } from "../../../helper/NumberOnlyCheck";
import axios from "../../../Axios/Axios";
import { Toast } from "../../../helper/toaster";
import { Url } from "../../../Axios/Axios";
import { Stack } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";

const drawerBleeding = 10;

const Root = styled("div")(({ theme }) => ({
  backgroundColor: "black",
}));

const StyledBox = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.mode === "light" ? "white" : grey[800],
}));

const Puller = styled("div")(({ theme }) => ({
  width: 70,
  height: 6,
  backgroundColor: theme.palette.mode === "light" ? TextBlue : grey[900],
  borderRadius: 3,
  position: "absolute",
  top: 8,
  left: "calc(50% - 35px)",
}));

function SwipeableEdgeDrawer(props) {
  const {
    window,
    open,
    setOpen,
    data,
    location,
    where,
    loadedData,
    setLoadedData,
    axiosCall,
    loadData,
    L1,
    L2,
  } = props;
  const navigate = useNavigate();

  const toggleDrawer = () => {
    setOpen(false);
  };

  const userJSON = localStorage.getItem("user");
  const user = userJSON && JSON.parse(userJSON);

  const [prDetails, setPrDetails] = useState({
    id: data?.id,
    item_code: data?.item_code ? data.item_code : "-",
    Po_no: data?.Po_no ? data.Po_no : "-",
    wh_cd: data?.wh_cd ? data.wh_cd : "-",
    grn_qty: data?.po_qty ? data?.po_qty : 0,
    grn_foc_qty: data?.po_foc_qty ? data?.po_foc_qty : 0,
    grn_batch_no: data?.batch_no ? data.batch_no : "-",
    grn_exp_date: data?.expiry ? data.expiry : "-",
    name: data?.item_name ? data.item_name : "-",
    unit_fraction: data?.unit_fraction ? data.unit_fraction : "",
  });

  const [inputData, setInputData] = useState({
    grn_qty: data?.grn_qty
      ? data.grn_qty / data?.unit_fraction
      : data?.po_qty / data?.unit_fraction,
    grn_foc_qty: data?.grn_foc_qty
      ? data?.grn_foc_qty / data?.unit_fraction
      : data?.po_foc_qty / data?.unit_fraction,
    grn_batch_no: data?.grn_batch_no ? data.grn_batch_no : "",
    grn_exp_date: data?.grn_exp_date ? data.grn_exp_date : null,
  });

  const [allow, setAllow] = React.useState(true);

  const prDetailsHandle = (e) => {
    const { name, value } = e.target;
    setInputData((prev) => {
      if (name === "grn_batch_no") {
        return { ...prev, [name]: value };
      }

      let newValue = value === "" ? "" : Number(value);
      if (
        name === "grn_qty" &&
        (newValue === "" ||
          (newValue >= 0 && newValue <= data.po_qty / data.unit_fraction))
      ) {
        return { ...prev, [name]: newValue };
      }
      if (
        name === "grn_foc_qty" &&
        (newValue === "" ||
          (newValue >= 0 && newValue <= data.po_foc_qty / data.unit_fraction))
      ) {
        return { ...prev, [name]: newValue };
      }
      return prev;
    });
  };

  const updateCall = async () => {
    const newData = {
      id: prDetails?.id ? prDetails.id : "",
      item_code: prDetails?.item_code ? prDetails.item_code : "",
      Po_no: prDetails?.Po_no ? prDetails.Po_no : "",
      wh_cd: prDetails?.wh_cd ? prDetails.wh_cd : "",
      grn_qty: inputData?.grn_qty
        ? inputData?.grn_qty * data?.unit_fraction
        : 0,
      grn_foc_qty: inputData?.grn_foc_qty
        ? inputData?.grn_foc_qty * data?.unit_fraction
        : 0,
      grn_batch_no: inputData?.grn_batch_no ? inputData.grn_batch_no : "",
      grn_exp_date: inputData?.grn_exp_date ? inputData.grn_exp_date : null,
      name: prDetails?.item_name ? prDetails.item_name : "",
    };

    const token = localStorage.getItem("userToken");
    if (!token) {
      throw new Error("User token is not available");
    }

    try {
      const response = await fetch(`${Url}update-grn`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(newData),
      });
      toggleDrawer();
      if (response.ok) {
        Toast("success", "Quantity updated successfully", 1500);
      } else {
        Toast("error", "Something went wrong", 1500);
      }

      if (where == "pr") {
        navigate("/receiving", {
          state: {
            data: location,
            where: "qrpage",
          },
        });
      } else {
        navigate(-1);
      }

      axiosCall(loadData);
    } catch (error) {
      console.log(error);
    }

    setAllow(true);
  };

  const styleForInputs = {
    mb: 2,
    fontSize: "14px",
    "& .MuiInputLabel-root": {
      left: 3,
      fontSize: "14px",
    },
  };

  // This is used only for the example
  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Root>
      <CssBaseline />
      {
        <Global
          styles={{
            ".MuiDrawer-root > .MuiPaper-root": {
              height: `calc(82% - ${drawerBleeding}px)`,
              overflow: "visible",
              borderTopLeftRadius: "40px",
              borderTopRightRadius: "40px",
              borderTop: "2px solid white",
              backgroundColor: "white",
            },
          }}
        />
      }

      <SwipeableDrawer
        container={container}
        anchor="bottom"
        open={open}
        onClose={() => toggleDrawer(false)}
        onOpen={() => {}}
        swipeAreaWidth={drawerBleeding}
        disableSwipeToOpen={false}
        ModalProps={{
          keepMounted: true,
        }}
      >
        <StyledBox
          sx={{
            position: "absolute",
            top: 0,
            borderTopLeftRadius: 120,
            borderTopRightRadius: 120,
            visibility: "visible",
            right: 0,
            left: 0,
          }}
        >
          <Puller />
          <Typography sx={{ mt: 5, textAlign: "center" }}>
             Product Details
          </Typography>

          <Box
            sx={{
              mt: 3,
              textAlign: "center",
              overflowY: "scroll",
            }}
          >
            <Box
              sx={{
                height: "300px",
                overflowY: "scroll",
                maxWidth: "400px",
                mx: "auto",
                "::-webkit-scrollbar": { display: "none" },
                px: 2,
              }}
            >
              <Stack alignItems="flex-start" justifyContent="center">
                <Stack direction="row" mb={2}>
                  <Typography sx={{ textAlign: "center", mr: 1 }}>
                    Item Code:{" "}
                  </Typography>
                  <Typography sx={{ textAlign: "center" }}>
                    {prDetails?.item_code}
                  </Typography>
                </Stack>
                <Stack direction="row" mb={2}>
                  <Typography sx={{ textAlign: "center", mr: 1 }}>
                    Item Name:{" "}
                  </Typography>
                  <Typography sx={{ textAlign: "center" }}>
                    {prDetails?.name}
                  </Typography>
                </Stack>
                <Stack direction="row" mb={2}>
                  <Typography sx={{ textAlign: "center", mr: 1 }}>
                    PO Qty:{" "}
                  </Typography>
                  <Typography sx={{ textAlign: "center" }}>
                    {prDetails?.grn_qty / prDetails?.unit_fraction}
                  </Typography>
                </Stack>
                <Stack direction="row" mb={3}>
                  <Typography sx={{ textAlign: "center", mr: 1 }}>
                    PO Foc Qty:{" "}
                  </Typography>
                  <Typography sx={{ textAlign: "center" }}>
                    {prDetails?.grn_foc_qty / prDetails?.unit_fraction}
                  </Typography>
                </Stack>

                <TextField
                  id="outlined-basic"
                  value={inputData.grn_qty}
                  name="grn_qty"
                  onChange={prDetailsHandle}
                  type="number"
                  size="small"
                  label="GRN Qty"
                  sx={{
                    fontSize: "14px",
                    mb: 1,
                    width: "100%",
                    ...styleForInputs,
                  }}
                  variant="outlined"
                />
                <TextField
                  id="outlined-basic"
                  value={inputData.grn_foc_qty}
                  name="grn_foc_qty"
                  defaultValue={0}
                  onChange={prDetailsHandle}
                  label="GRN Foc Qty"
                  type="number"
                  size="small"
                  sx={{
                    fontSize: "14px",
                    mb: 1,
                    width: "100%",
                    ...styleForInputs,
                  }}
                  variant="outlined"
                />
                <TextField
                  id="outlined-basic"
                  value={inputData.grn_batch_no}
                  name="grn_batch_no"
                  onChange={prDetailsHandle}
                  label="Batch No."
                  type="number"
                  size="small"
                  sx={{
                    fontSize: "14px",
                    mb: 1,
                    width: "100%",
                    ...styleForInputs,
                  }}
                  variant="outlined"
                />
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Expiry Date"
                    defaultValue={
                      inputData.grn_exp_date
                        ? dayjs(inputData.grn_exp_date)
                        : null
                    }
                    size="medium"
                    sx={{ width: "100%" }}
                    onChange={(e) => {
                      setInputData((prev) => ({
                        ...prev,
                        grn_exp_date: e ? dayjs(e).format("YYYY-MM-DD") : "",
                      }));
                    }}
                    name="grn_exp_date"
                  />
                </LocalizationProvider>
              </Stack>
            </Box>
            {/* <Box sx={{ height: "300px", overflowY: "scroll" }}>
                            <TextField
                                id="outlined-basic"
                                value={prDetails.item_code}
                                name="item_code"
                                defaultValue={0}
                                disabled={true}
                                onChange={(e) => {
                                    prDetailsHandle(e);
                                }}
                                label="Item Code"
                                sx={{
                                    fontSize: "14px",
                                    mb: 1,
                                    ...styleForInputs,
                                    mt: 2,
                                }}
                                variant="outlined"
                            />

                            <TextField
                                id="outlined-basic"
                                value={prDetails.name}
                                name="name"
                                disabled={true}
                                defaultValue={0}
                                onChange={(e) => {
                                    prDetailsHandle(e);
                                }}
                                label="Name"
                                sx={{
                                    fontSize: "14px",
                                    mb: 1,
                                    ...styleForInputs,
                                }}
                                variant="outlined"
                            />

                            <TextField
                                id="outlined-basic"
                                value={prDetails.Po_no}
                                name="Po_no"
                                disabled={true}
                                defaultValue={0}
                                onChange={(e) => {
                                    prDetailsHandle(e);
                                }}
                                label="Po No"
                                sx={{
                                    fontSize: "14px",
                                    mb: 1,
                                    ...styleForInputs,
                                }}
                                variant="outlined"
                            />

                            <TextField
                                id="outlined-basic"
                                value={prDetails.grn_batch_no}
                                name="grn_batch_no"
                                disabled={true}
                                defaultValue={0}
                                onChange={(e) => {
                                    prDetailsHandle(e);
                                }}
                                label="Batch No"
                                sx={{
                                    fontSize: "14px",
                                    mb: 1,
                                    ...styleForInputs,
                                }}
                                variant="outlined"
                            />

                            <TextField
                                id="outlined-basic"
                                value={prDetails.grn_exp_date}
                                name="grn_exp_date"
                                disabled={true}
                                defaultValue={0}
                                onChange={(e) => {
                                    prDetailsHandle(e);
                                }}
                                label="Expiry"
                                sx={{
                                    fontSize: "14px",
                                    mb: 1,
                                    ...styleForInputs,
                                }}
                                variant="outlined"
                            />


                            <TextField
                            id="outlined-basic"
                            value={prDetails.grn_qty}
                            name="grn_qty"
                        
                            defaultValue={0}
                            onChange={(e) => {
                              if(isNumberOnly(e)){
                                prDetailsHandle(e);
                              }
                            }}
                            label="GRN Qty"
                            sx={{
                                fontSize: "14px",
                                mb: 1,
                                ...styleForInputs,
                            }}
                            variant="outlined"
                        />

                        <TextField
                        id="outlined-basic"
                        value={prDetails.grn_foc_qty}
                        name="grn_foc_qty"
                        
                        defaultValue={0}
                        onChange={(e) => {
                          if(isNumberOnly(e)){
                            prDetailsHandle(e);
                          }
                        }}
                        label="GRN Foc Qty"
                        sx={{
                            fontSize: "14px",
                            mb: 1,
                            ...styleForInputs,
                        }}
                        variant="outlined"
                    />

                        </Box> */}

            <Box
              sx={{
                display: "flex",
                width: "90%",
                justifyContent: "space-evenly",
                mx: "auto",
                mt: 1,
              }}
            >
              <Btn
                onClick={() => {
                  toggleDrawer();

                  if (where == "pr") {
                    navigate("/receiving", {
                      state: {
                        data: location,
                        where: "qrpage",
                      },
                    });
                  } else {
                    navigate(-1);
                  }
                  setAllow(true);
                }}
                variant="contained"
                opacity={0.8}
                sx={{
                  borderRadius: 2,
                  backgroundColor: "red",
                  px: "2rem",
                }}
              >
                Cancel
              </Btn>
              <Btn
                onClick={updateCall}
                disabled={L1 === 1 && L2 === 1 ? true : false}
                variant="contained"
                opacity={0.8}
                sx={{
                  borderRadius: 2,
                  backgroundColor: "green",
                  px: "2rem",
                }}
              >
                Submit
              </Btn>
            </Box>
          </Box>
        </StyledBox>
      </SwipeableDrawer>
    </Root>
  );
}

SwipeableEdgeDrawer.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default SwipeableEdgeDrawer;
