import React, { useState } from "react";
import Header from "../../../helper/Header";
import {
  Box,
  Button,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Download, PictureAsPdfOutlined } from "@mui/icons-material";

const POGRN = () => {
  const rows = [
    {
      itemCode: "IT001",
      itemName: "Item 1",
      poQty: 100,
      grnQty: 95,
      variance: 5,
    },
    {
      itemCode: "IT002",
      itemName: "Item 2",
      poQty: 150,
      grnQty: 150,
      variance: 0,
    },
    {
      itemCode: "IT003",
      itemName: "Item 3",
      poQty: 200,
      grnQty: 180,
      variance: 20,
    },
    {
      itemCode: "IT004",
      itemName: "Item 4",
      poQty: 300,
      grnQty: 290,
      variance: 10,
    },
    {
      itemCode: "IT005",
      itemName: "Item 5",
      poQty: 250,
      grnQty: 245,
      variance: 5,
    },
  ];

  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);

  const handleSearch = () => {
    console.log("Searching with dates:", { fromDate, toDate });
  };

  const handleExport = () => {
    console.log("Export Function will implment later");
  };

  return (
    <Box sx={{ width: "93%", mx: "auto", mt: 2 }}>
      <Header />

      <Box>
        <Box
          sx={{
            p: 3,
          }}
        >
          <Typography variant="h5" sx={{ mb: 3, color: "white" }}>
            Purchase Order vs GRN Report
          </Typography>

          <Box
            sx={{
              py: 3,
              display: "flex",
              gap: 2,
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
              <Stack direction="row" spacing={2}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="From Date"
                    value={fromDate}
                    onChange={(newValue) => setFromDate(newValue)}
                    slotProps={{
                      textField: {
                        size: "small",
                        sx: {
                          backgroundColor: "transparent",
                          border: "1px solid white",
                          color: "white",
                          "& .MuiOutlinedInput-input": {
                            color: "white",
                          },
                          "& .MuiSvgIcon-root": {
                            color: "white",
                          },
                          "& .MuiInputLabel-root": {
                            backgroundColor: "#000D1D",
                            color: "white",
                            "&.Mui-focused": {
                              color: "white",
                            },
                          },
                        },
                      },
                    }}
                  />
                  <DatePicker
                    label="To Date"
                    value={toDate}
                    onChange={(newValue) => setToDate(newValue)}
                    slotProps={{
                      textField: {
                        size: "small",
                        sx: {
                          backgroundColor: "transparent",
                          border: "1px solid white",
                          color: "white",
                          "& .MuiOutlinedInput-input": {
                            color: "white",
                          },
                          "& .MuiSvgIcon-root": {
                            color: "white",
                          },
                          "& .MuiInputLabel-root": {
                            color: "white",
                            backgroundColor: "#000D1D",
                            "&.Mui-focused": {
                              color: "white",
                            },
                          },
                        },
                      },
                    }}
                  />
                </LocalizationProvider>
              </Stack>
              <Button
                variant="contained"
                onClick={handleSearch}
                sx={{ height: 40 }}
              >
                Search
              </Button>
            </Stack>
            <Box>
              <Button
                variant="contained"
                onClick={handleExport}
                sx={{ height: 40 }}
              >
                <Typography
                  component="span"
                  sx={{ display: { xs: "none", md: "block" } }}
                >
                  Export
                </Typography>
                <Download />
              </Button>
            </Box>
          </Box>

          <TableContainer
            component={Paper}
            sx={{
              backgroundColor: "transparent",
              border: "1px solid white",
              "&::-webkit-scrollbar": { display: "none" },
            }}
          >
            <Table sx={{ width: "100%" }} aria-label="PO vs GRN table">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ color: "white", fontWeight: "bold" }}>
                    Item Code
                  </TableCell>
                  <TableCell sx={{ color: "white", fontWeight: "bold" }}>
                    Item Name
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ color: "white", fontWeight: "bold" }}
                  >
                    PO Qty
                  </TableCell>
                  <TableCell
                    align="right"
                    sx={{ color: "white", fontWeight: "bold" }}
                  >
                    GRN Qty
                  </TableCell>
                  <TableCell
                    align="right"
                    sx={{ color: "white", fontWeight: "bold" }}
                  >
                    Variance
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <TableRow
                    key={row.itemCode}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell
                      component="th"
                      scope="row"
                      sx={{ color: "white" }}
                    >
                      {row.itemCode}
                    </TableCell>
                    <TableCell sx={{ color: "white" }}>
                      {row.itemName}
                    </TableCell>
                    <TableCell align="center" sx={{ color: "white" }}>
                      {row.poQty}
                    </TableCell>
                    <TableCell align="right" sx={{ color: "white" }}>
                      {row.grnQty}
                    </TableCell>
                    <TableCell
                      align="right"
                      sx={{
                        color: row.variance > 0 ? "error.main" : "success.main",
                      }}
                    >
                      {row.variance}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
    </Box>
  );
};

export default POGRN;
