import React, { lazy, useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import { WhiteBorder, colorWhite } from "../../styled-Component/elements";
import { useNavigate, useLocation } from "react-router-dom";
import PopulModel from "./helper/popupMdel";

import Qrscanner from "./helper/QrCodeScanner";

const PrModelPopUp = lazy(() => import("./helper/popupMdelForPR"));

function Qrcodepage() {
    const locationInit = useLocation();

    const location = locationInit.state.data;
    const where = locationInit.state.where;
    let loadedData = locationInit.state.loadedData;
    const state = locationInit.state;

    // console.log("where", where);
    // console.log("location", location);
    // console.log("loadedData", loadedData);
    // console.log("state", state);

    const navigate = useNavigate();

    const [openQr, setOpenQr] = useState(false);

    const [open, setOpen] = useState(!false);

    const [prData, setPrData] = useState({});

    const [scanStatus, setScanStatus] = useState(false);
    const [binLocation, setBinLocation] = useState('');
    const [isScanned, setIsScanned] = useState(false); 

    const toggleDrawer = () => {
        setOpen(!open);
    };
    return (
        <Box sx={{ p: 3 }}>
            <Typography
                component={"h2"}
                variant="h4"
                sx={{
                    color: "white",
                    textAlign: "center",
                    textDecoration: "underline",
                }}
            >
                QR CODE
            </Typography>
            <Box
                sx={{
                    ...WhiteBorder,
                    textAlign: "center",
                    mt: 6,
                    width: "99%",
                    mx: "auto",
                    boxSizing: "border-box",
                }}
            >
                <Qrscanner
                    datas={location}
                    where={where}
                    setScanStatus={setScanStatus}
                    loadedData={loadedData}
                    setPrData={setPrData}
                    binLocation={binLocation}
                    setBinLocation={setBinLocation}
                    isScanned={isScanned}
                    setIsScanned={setIsScanned}
                />
            </Box>
            <Box sx={{ textAlign: "center", mt: 4 }}>
                <Button
                    sx={{ ...WhiteBorder, color: colorWhite }}
                    variant="outlined"
                    onClick={() => {
                        if (where == "pr") {
                            navigate("/receiving", {
                                state: { data: location, where: "qrpage" },
                            });
                        } else {
                            navigate(-1);
                        }
                    }}
                >
                    Back
                </Button>
            </Box>
            {/* {scanStatus &&
                (where == "pr" ? (
                    <PrModelPopUp
                        open={open}
                        setOpen={setOpen}
                        where={where}
                        data={prData}
                        location={location}
                    />
                ) : (
                    <PopulModel
                        open={open}
                        setOpen={setOpen}
                        data={location}
                        where={where}
                    />
                ))} */}

            {scanStatus && where === "invoiceList" && (
                <PopulModel
                    open={open}
                    setOpen={setOpen}
                    data={location}
                    where={where}
                    binLocation={binLocation}
                />
            )}
        </Box>
    );
}

export default Qrcodepage;
