import React, { useState } from "react";
import { Url } from "../../../Axios/Axios";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
} from "@mui/material";
import BarCodeInputValidator from "../../../helper/barCodeValidator";
import { Toast } from "../../../helper/toaster";
import { BgBlackImage } from "../../../styled-Component/colors";
import { useLocation } from "react-router-dom";
import PopupForRackPlacement from "../../../components/TablePage/PopupForRackPlacement";
const TopBarCodeTable = ({ itemsList }) => {
  const location = useLocation();
  const [drawerNewOpen, SetDrawerNewOpen] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [popupData, setPopupData] = useState([]);
  const [responseData, setResponseData] = useState("");
  const handlebarCodeChecker = async (validBarNumber) => {
    try {
      const token = localStorage.getItem("userToken");
      if (!token) {
        throw new Error("User token is not available");
      }
      const newValidBarNumber = BarCodeInputValidator(validBarNumber);
      const response = await fetch(`${Url}check-barcode`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ barcode: newValidBarNumber }),
      }).then((response) => response.json());

      setResponseData(response.data);

      const newItem = itemsList?.filter(
        (item) => item.item_code === response.data.item_code
      );
      if (newItem.length > 0) {
        setPopupData(newItem);
        if (response.data.binloc) {
          SetDrawerNewOpen(true);
        } else {
          setOpen(true);
        }
      } else {
        SetDrawerNewOpen(false);
        Toast("error", "This Barcode is not matched", 1600);
      }
    } catch (error) {
      console.error("Error during barcode validation:", error);
      Toast("error", "This Barcode is not matched", 1600);
    }
  };
  const handleBarInputChange = (e) => {
    if (e.key === "Enter") {
      handlebarCodeChecker(e.target.value);
    }
  };

  const handleBarCodeInputClear = () => {
    document.getElementById("barcode-input").value = "";
  };

  const handleCloseDrawer = () => {
    SetDrawerNewOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpenDrawer = () => {
    setOpen(false);
    SetDrawerNewOpen(true);
  };

  const TableTextColor = { color: "white", fontSize: "17px" };
  const TableStyle = {
    backgroundColor: BgBlackImage,
    color: "white !important",
  };

  const handelSubmit = async (data) => {
    try {
      const token = localStorage.getItem("userToken");
      if (!token) {
        throw new Error("User token is not available");
      }

      const formData = new FormData();
      Object.keys(data).forEach((key) => {
        formData.append(key, data[key]);
      });
      const response = await fetch(`${Url}update-quantity`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      }).then((response) => response.json());

      if (!response.success) {
        Toast("error", response.message, 1600);
      } else {
        Toast("success", response.message, 1600);
      }
      handleCloseDrawer();
    } catch (error) {
      console.error("Error", error);
    }
  };

  return (
    <>
      <TableContainer
        component={Paper}
        sx={{
          position: "static",
          width: { xs: "100%", md: "75%" },
          mx: "auto",
        }}
      >
        <Table sx={{ minWidth: "100%" }} aria-label="simple table">
          <TableBody sx={{ border: "2px solid white" }}>
            <TableRow
              sx={{
                ...TableStyle,
                "&:last-child td, &:last-child th": {
                  border: 0,
                },
              }}
            >
              <TableCell sx={{ ...TableTextColor }} component="th" scope="row">
                Po Date
              </TableCell>
              <TableCell sx={{ ...TableTextColor }} align="right">
                {location.state.data.invoice_date
                  ? location.state.data.invoice_date
                  : "-"}
              </TableCell>
            </TableRow>
            <TableRow
              sx={{
                display: "none",
                ...TableStyle,
                "&:last-child td, &:last-child th": {
                  border: 0,
                },
              }}
            >
              <TableCell sx={{ ...TableTextColor }} component="th" scope="row">
                Supplier Name
              </TableCell>
              <TableCell sx={{ ...TableTextColor }} align="right">
                {/* {supplier} */}
              </TableCell>
            </TableRow>
            <TableRow
              sx={{
                ...TableStyle,
                "&:last-child td, &:last-child th": {
                  border: 0,
                },
              }}
            >
              <TableCell sx={{ ...TableTextColor }} component="th" scope="row">
                Po Number
              </TableCell>
              <TableCell sx={{ ...TableTextColor }} align="right">
                {location.state.data.invoice_no
                  ? location.state.data.invoice_no
                  : "-"}
              </TableCell>
            </TableRow>
            <TableRow
              sx={{
                ...TableStyle,
                "&:last-child td, &:last-child th": {
                  border: 0,
                },
              }}
            >
              <TableCell
                sx={{
                  ...TableTextColor,
                  pr: 0,
                  color: "#fff !important",
                }}
                component="th"
                scope="row"
              >
                <TextField
                  sx={{
                    width: "100%",
                    border: 1,
                    borderRadius: "2px",
                    "& .MuiInputBase-input": {
                      color: "white",
                    },
                  }}
                  id="barcode-input"
                  size="small"
                  onKeyPress={handleBarInputChange}
                />
              </TableCell>
              <TableCell sx={{ ...TableTextColor }} align="right">
                <Button
                  variant="danger"
                  size="small"
                  sx={{
                    "& .MuiButton-root": {
                      padding: "5px !important",
                    },
                    backgroundColor: "#dc3545",
                  }}
                  onClick={handleBarCodeInputClear}
                  // onClick={() =>
                  //     navigate("/scanner", {
                  //         state: {
                  //             data: loadData,
                  //             loadedData: loadedData,
                  //             where: "pr",
                  //         },
                  //     })
                  // }
                >
                  Clear
                </Button>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Bin Location Not mapped for this item, Do you want dynamic location
            ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>No</Button>
          <Button onClick={handleOpenDrawer}>Yes</Button>
        </DialogActions>
      </Dialog>
      <PopupForRackPlacement
        handleCloseDrawer={handleCloseDrawer}
        open={drawerNewOpen}
        binLocation={responseData.binloc}
        disabled={true}
        inBuitScanner={true}
        popupData={popupData[0]}
        onSubmit={handelSubmit}
      />
    </>
  );
};

export default TopBarCodeTable;
